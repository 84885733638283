import request from '@/utils/request'

// api地址
const api = {
  all: '/ams/formInfo',
  save: '/ams/formInfo',
  update: '/ams/formInfo/update',
  info: '/ams/formInfo/',
  delete: '/ams/formInfo/',
  page: '/ams/formInfo/page',
  batch: '/ams/formInfo/batch',
  assets: '/ams/formAssets/page',
  flowNode: '/ams/formInfo/flowNode',
  formNode: '/ams/formInfo/node',
  agree: '/ams/formInfo/agree',
  rejeck: '/ams/formInfo/rejeck',
  isApprove: '/ams/formInfo/isApprove',
  formInfoPdf: '/ams/formInfoPdf',
  formInfoPdfUpdate: '/ams/formInfoPdf/update',
  downloadPdf: '/ams/formInfoPdf/download/'
}

/**
 * 根据id查询
 * @param id id
 * @returns {Promise<Object>}
 */
export function getById(id) {
  return new Promise((resolve, reject) => {
    request.get(api.info + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 查询列表
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function page(params) {
  return new Promise((resolve, reject) => {
    request.get(api.page, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 查询列表
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function all(params) {
  return new Promise((resolve, reject) => {
    request.get(api.all, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 根据id删除
 * @param id id
 * @returns {Promise<>}
 */
export function deleteById(id) {
  return new Promise((resolve, reject) => {
    request.delete(api.delete + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 批量删除
 * @param id id
 * @returns {Promise<>}
 */
export function deleteBatch(data) {
  return new Promise((resolve, reject) => {
    request.delete(api.deleteBatch, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 添加或修改
 * @param data 参数
 * @returns {Promise<>}
 */
export function save(data, isUpdate) {
  if (isUpdate) {
    return new Promise((resolve, reject) => {
      request.post(api.update, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  } else {
    return new Promise((resolve, reject) => {
      request.post(api.save, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  }
}

/**
 * 批量添加或修改
 * @param data 参数
 * @returns {Promise<>}
 */
export function saveBatch(data, isUpdate) {
  if (isUpdate) {
    return new Promise((resolve, reject) => {
      request.put(api.batch, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  } else {
    return new Promise((resolve, reject) => {
      request.post(api.batch, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  }
}

export function assets(params) {
  return new Promise((resolve, reject) => {
    request.get(api.assets, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

export function flowNode(params) {
  return new Promise((resolve, reject) => {
    request.get(api.flowNode, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

export function formNode(params) {
  return new Promise((resolve, reject) => {
    request.get(api.formNode, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

export function agree(params) {
  return new Promise((resolve, reject) => {
    request.post(api.agree, params).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

export function rejeck(params) {
  return new Promise((resolve, reject) => {
    request.post(api.rejeck, params).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

export function isApprove(params) {
  return new Promise((resolve, reject) => {
    request.post(api.isApprove, params).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

// 添加表单数据Pdf
export function formInfoPdf(params, isUpdate) {
  if (isUpdate) {
    return new Promise((resolve, reject) => {
      request.post(api.formInfoPdfUpdate, params).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  } else {
    return new Promise((resolve, reject) => {
      request.post(api.formInfoPdf, params).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  }
}
// 获取表单数据Pdf
export function getFormInfoPdf(params) {
  return new Promise((resolve, reject) => {
    request.get(api.formInfoPdf, { params }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

// 下载Pdf
export function downloadPdf(id) {
  return new Promise((resolve, reject) => {
    request.get(api.downloadPdf + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
